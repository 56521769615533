import React , { useEffect, useState } from 'react';
// page sections
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useTranslation } from 'react-i18next'
import {  useNavigate  } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { AppBox } from 'components/AppDashboard/AppBox';
import { AppDiv } from 'components/AppDiv/AppDiv';
import {AppBackImg} from 'components/AppBackgroundImg/AppImg'
import { AppLoader } from "components/AppLoader/AppLoader";
import {CustomersTable} from './components/customerTable/CustomerTable'
import 'react-tabs/style/react-tabs.css';

import './tabs.css';


const moment = require('moment');

function Button(props) {
  return (
    <button className="my-button" onClick={props.onClick}>
      {props.label}
    </button>
  );
}



export const  AppTabs = ( {getItems, items,hasNext,hasBack,hasData  = false,reloadTable, cutomersDetails, agents } ) =>  {

  const navigate = useNavigate();
  const { t ,i18n} = useTranslation();
    const[loader, setLoader] = useState(false)
    const[actionStatus,setActionStatus]= useState('')
  
    const currency  =  i18n.language ==='he' ? "ILS"  :"ILS"
   

  //   const handleApprove =async  (payment) =>{
  //     try{
  //       setLoader(true)
  //       setPaymentId(payment.id)
  // //  await approvePayment({data: {paymentId:payment.id} })

  //       setLoader(false)
  //       setActionStatus('IN_PROCESS')
  //       await new Promise(resolve => setTimeout(resolve, 60000));

  //       let userPayments = {}//await getUserPayments()
  //       let paymentsResults = userPayments.data.payments
  //       paymentsResults = paymentsResults.sort((a, b) => a.date - b.date);
  //       setPayments(paymentsResults)
  //       setPaymentId('')
  //       setActionStatus('')
  //     }catch(e){
  //       localStorage.clear()
  //       navigate('/login')
  //     }


    // }
  

    
    const boxCssClass =  'app-user-crypto-data-wrapper-not-empty'  
    return( 
        <Tabs>
            <TabList>
                <Tab>רשימת לקוחות</Tab>
               
            </TabList>
           
            <TabPanel>
                  
              <CustomersTable getItems={getItems} agents={agents} hasBack={hasBack} hasNext={hasNext} cutomersDetails={cutomersDetails} items={items} reloadTable={reloadTable}/>
                    
            </TabPanel>
           
        </Tabs>
        
    )
} 

