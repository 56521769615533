import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import  axiosOptions from "api/project/axios/axios";
import 'chart.js/auto';
import './chart.css';
import { getDataFromStorage, cleanAll } from '../../../../../../../localStorage/localStorage'
const StatsBarChart = () => {
  const [stats, setStats] = useState({});
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  useEffect(() => {
    const fetchStats = async () => {
      try {
        if ( getDataFromStorage('app-token') ) {
          axiosOptions.headers['Authorization']  = 'Bearer ' + getDataFromStorage('app-token'); 
        }
        const response = await axios.get('https://rsc3d4it9h.execute-api.eu-west-1.amazonaws.com/dev/customers/stats', axiosOptions);
        setStats(response.data);
      } catch (error) {
        console.error('Error fetching stats:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, []);

  const data = {
    labels: Object.keys(stats).map(stat=>t(stat)),
    datasets: [
      {
        label: 'סטטיסטיקה לקוחות',
        data: Object.values(stats).map(stat=>t(stat)),
        backgroundColor: [
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)'
        ],
        borderColor: [
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)'
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        stepSize: 1,
        ticks: {
          precision: 0, // Ensures that only integer values are displayed
        }
      },
    },
    maintainAspectRatio: false, // Allows custom size without maintaining aspect ratio

  };

  return (
    <div>
      {loading ? (
        <div className="loader"></div>
      ) : (
        <div style={{ width: '600px' }}>
        
        <Bar data={data} options={options} />
        </div>
      )}
    </div>
  );
};

export default StatsBarChart;
