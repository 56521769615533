import React, { useEffect, useState } from 'react';
import styles from './login.module.css';
import { Link, useNavigate } from 'react-router-dom';
import { login } from 'api/user/login';
import axios from 'axios';

const Loader = () => (
  <div className={styles.loader}>
    <div className={styles.spinner} />
  </div>
);

const Page = () => {
  const navigate = useNavigate();
  const [formdata, setFormData] = useState({});
  const [formErr, setFormErr] = useState([]);
  const [showVerify, setShowVerify] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [code, setCode] = useState('');
  const [codeVerifier, setCodeVerifier] = useState('');
  const [session, setSession] = useState('');
  const [stage, setStage] = useState('phone'); // 'phone' or 'code'

  const query = new URLSearchParams(window.location.search);
  const userName = query.get('userName');
  const [phoneNumber, setPhoneNumber] = useState('');

  const formatPhoneNumber = (value) => {
    if (!value) return value;

    // Remove all non-digit characters
    const phoneNumber = value.replace(/[^\d]/g, '');

    // Slice and return the formatted phone number
    const areaCode = phoneNumber.slice(0, 3);
    const middle = phoneNumber.slice(3, 6);
    const last = phoneNumber.slice(6, 10);

    if (phoneNumber.length < 4) return areaCode;
    if (phoneNumber.length < 7) return `${areaCode}${middle}`;
    return `${areaCode}${middle}${last}`;
  };

  const handleChange = (event) => {
    const formattedPhoneNumber = formatPhoneNumber(event.target.value);
    console.log(`formattedPhoneNumber ${formattedPhoneNumber}`)
    
    setPhoneNumber(formattedPhoneNumber);
  };

  const handlePhoneChange = (event) => {
    setPhoneNumber(formatPhoneNumber(event.target.value));
  };
  const handleCodeChange = (event) => {
    setCode(event.target.value);
  };

  const generateCodeVerifier = () => {
    const array = new Uint8Array(32);
    window.crypto.getRandomValues(array);
    return Array.from(array, byte => byte.toString(16).padStart(2, '0')).join('');
  };
  
  const sha256 = async (buffer) => {
    const digest = await crypto.subtle.digest('SHA-256', buffer);
    return new Uint8Array(digest);
  };
  
  const base64urlencode = (arrayBuffer) => {
    return btoa(String.fromCharCode.apply(null, new Uint8Array(arrayBuffer)))
      .replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
  };
  
  const generateCodeChallenge = async (verifier) => {
    const hashed = await sha256(new TextEncoder().encode(verifier));
    return base64urlencode(hashed);
  };
  
  const generateCodeVerifierAndChallenge = async () => {
    const verifier = generateCodeVerifier();
    const challenge = await generateCodeChallenge(verifier);
    return { verifier, challenge };
  };
 
 

  const submitCode = async (event) => {
    event.preventDefault();
    setShowLoader(true);
    try {
      const response = await axios.post('https://xa45gi8acb.execute-api.eu-west-1.amazonaws.com/dev/user/verifyCode', {
        code,
        codeVerifier, // Use the same verifier as in the first request
        session,
        username: phoneNumber,
      });

      localStorage.setItem('app-token', response.data.AccessToken);
      setShowLoader(false);
      navigate('/cutomers')
      // Handle successful verification
    } catch (error) {
      console.error('Error during code submission:', error);
      setShowLoader(false);
      // Handle error appropriately
    }
  };

  useEffect(() => {
    // if (localStorage.getItem('app-token') != null) {
    //   navigate('/customers');
    // }
    const verify = query.get('verify');
    let userName = query.get('userName');
    if (verify === 'true' && userName != '') {
      setShowVerify(true);
      setFormData({
        userName: userName,
      });
    }
  }, []);

  const handleUsernameChange = (event) => {
    setFormData({
      ...formdata,
      userName: event.target.value,
    });
  };

  const handlePasswordChange = (event) => {
    setFormData({
      ...formdata,
      password: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    setShowLoader(true);
    setFormErr([]);
    event.preventDefault();

    const err = [];

    if (err.length === 0) {
      try {
        const { verifier, challenge } = await generateCodeVerifierAndChallenge();
        setCodeVerifier(verifier)
        const data = {
          "username":phoneNumber,
          "codeChallenge":challenge
        }
        console.log(`data ${JSON.stringify(data)}`)
        const res = await login({ data: data });
        console.log(`res ${JSON.stringify(res.data)}`)
        setSession(res.data.session);
        setStage('code');
         setShowLoader(false);
        console.log('res', res);
      } catch (e) {
        console.log('r', e);
        setShowLoader(false);
        setFormErr(err);
      }
    } else {
      setShowLoader(false);
      setFormErr(err);
    }
  };


  return (
    <div className={styles.loginWrapper}>
      <div className={styles.loginBox}>
        <form onSubmit={stage === 'phone' ? handleSubmit: submitCode}>
        {stage === 'code' && (
          <input 
          className={styles.input}
            type="text" 
            value={code} 
            onChange={handleCodeChange} 
            placeholder=" קוד שהתקבל מהנייד" 
          />
      )}
      {stage === 'phone' && (
        <div>
           <label htmlFor="lastName">טלפון סוכן:</label>
           <input 
             className={styles.input}
      type="tel" 
      value={phoneNumber} 
      onChange={handleChange} 
      placeholder="05XXXXXXXX" 
    /></div>
      )}
          {/* <input
            className={styles.input}
            type="password"
            placeholder="Password"
            value={formdata.password || ''}
            onChange={handlePasswordChange}
          /> */}
          <button className={styles.button} type="submit">
            התחבר
          </button>
        </form>
        {showLoader && <Loader />}
      </div>
    </div>
  );
};

export default Page;
