import React , { useEffect, useState } from 'react';
import LoggedInLayout from 'layouts/LoggedInLayout'
import { useNavigate  } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

import { AppSection } from 'components/AppSection/AppSection';
import { AppContainer } from 'components/AppContainer/AppContainer';
import { AppRow } from 'components/AppRow/AppRow';
import { AppCol } from 'components/AppCol/AppCol';
import { AppLoggedInTitle } from 'components/AppDashboard/AppLoggedInTitle';
import { AppBox } from 'components/AppDashboard/AppBox';
// page sections
import './page.css';
import { AppTabs } from "./components/tabs";
import { AppLoader } from "components/AppLoader/AppLoader";
import {getCustomers} from 'api/project/getProject'
import StatsBarChart from './components/tabs/components/charts/StatsBarChart'
import  axiosOptions from "api/project/axios/axios";
import axios from 'axios';  
import { getDataFromStorage, cleanAll } from '../../../localStorage/localStorage'
function  Page() {
  const { t , i18n} = useTranslation();
  const navigate = useNavigate();
  const [loadProject, setLoadProject]= useState(true)
  const [customers, setCustomers]= useState([])
  const [pages, setPages]= useState([])
  const [lastfilter, setLastFilter]= useState()
  const [lastPage, setLastPage]= useState()
  const [hasNext, setHasMext]= useState(false)
  const [hasBack, setHasBack]= useState(false)
  const [aggentDetails, setAgentDetails]= useState([])
  const [cutomersDetails, setCustomerDetails] = useState(null)
  const [agents, setAgents] = useState(null)

  const getCustomerDetails = async (accountId) =>{
    try{
    if ( getDataFromStorage('app-token') ) {
      axiosOptions.headers['Authorization']  = 'Bearer ' + getDataFromStorage('app-token'); 
    }
    const response = await axios.get(`https://rsc3d4it9h.execute-api.eu-west-1.amazonaws.com/dev/customers/${accountId}`,axiosOptions);
    return response.data
  }catch(e){
    return {}
  }
  }

  const getAgent = async (agetId) =>{
    try{
    if ( getDataFromStorage('app-token') ) {
      axiosOptions.headers['Authorization']  = 'Bearer ' + getDataFromStorage('app-token'); 
    }
    const response = await axios.get(`https://rsc3d4it9h.execute-api.eu-west-1.amazonaws.com/dev/agent/${agetId}`,axiosOptions);
    return response.data
  }catch(e){
    return {}
  }
  }
  console.log('language:  ' +   i18n.language)

  const getItems = async (filter, operation, queryLast) =>{
    setLastFilter(filter)
    console.log(`filter ${filter} operation ${operation}`)
    if(operation){
      if(operation === 'NEXT'){
        const next = pages.length > 0 ? pages[ pages.length - 1] : null
        console.log(`next ${next} filter ${filter}`)
        setLastPage(next)
        const customers = await getCustomers(filter, next)
        console.log(`customers.data.lastEvaluatedKey ${JSON.stringify(customers.data.lastEvaluatedKey)}`)
        if(customers.data.lastEvaluatedKey){
          setHasMext(true)
          pages.push(customers.data.lastEvaluatedKey)
          setPages(pages)
        }else{
          setHasMext(false)
        }
        setHasBack(true)
        setCustomers(customers.data.items)
        const allDetails = await Promise.all(customers.data.items.map(async item =>await  getCustomerDetails(item.accountId)))
        setCustomerDetails(allDetails)
        const agentIds = allDetails.map(detail => detail.agentId)
        const parentAgentIds = allDetails.map(detail => detail.parentAgentId)
        const topAgentIds = allDetails.map(detail => detail.topAgentId)
        const combinedArray = [...agentIds, ...parentAgentIds, ...topAgentIds];
      // Filter out "N/A" and empty strings, then get unique values
        const uniqueValidIds = [...new Set(combinedArray.filter(id => id && id !== "N/A"))];
        const agents = await Promise.all(uniqueValidIds.map(async agentId => await getAgent(agentId)))
        setAgents(agents)
      }
      if(operation === 'BACK'){
        let back
        setHasMext(true)
        if(pages.length > 1){
        
           pages.pop()
           back=  pages[ pages.length - 1]
        }else{
          setHasBack(false)
          back = null
        }
        setLastPage(back)
        const customers = await getCustomers(filter, back)
        console.log(`pages ${JSON.stringify(pages)}`)
        setPages(pages)
        setCustomers(customers.data.items)

        const allDetails = await Promise.all(customers.data.items.map(async item =>await  getCustomerDetails(item.accountId)))
        setCustomerDetails(allDetails)


        const agentIds = allDetails.map(detail => detail.agentId)
        const parentAgentIds = allDetails.map(detail => detail.parentAgentId)
        const topAgentIds = allDetails.map(detail => detail.topAgentId)
        const combinedArray = [...agentIds, ...parentAgentIds, ...topAgentIds];
      // Filter out "N/A" and empty strings, then get unique values
        const uniqueValidIds = [...new Set(combinedArray.filter(id => id && id !== "N/A"))];
        const agents = await Promise.all(uniqueValidIds.map(async agentId => await getAgent(agentId)))
        setAgents(agents)

      }

    }else{
      setHasMext(false)
      setHasBack(false)
      queryLast ? setLastPage(queryLast) : setLastPage(null)
      const customers = await getCustomers(filter, queryLast? queryLast : null)
      localStorage.setItem("customers" , JSON.stringify(customers.data.items));
      setCustomers(customers.data.items)
      
      if(customers.data.lastEvaluatedKey){
        setHasMext(true)
        setPages([customers.data.lastEvaluatedKey])
      }else{
        setPages([])
      }
      const allDetails = await Promise.all(customers.data.items.map(async item => await getCustomerDetails(item.accountId)))
      setCustomerDetails(allDetails)
      const agentIds = allDetails.map(detail => detail.agentId)
      const parentAgentIds = allDetails.map(detail => detail.parentAgentId)
      const topAgentIds = allDetails.map(detail => detail.topAgentId)
      const combinedArray = [...agentIds, ...parentAgentIds, ...topAgentIds];
    // Filter out "N/A" and empty strings, then get unique values
      const uniqueValidIds = [...new Set(combinedArray.filter(id => id && id !== "N/A"))];
      const agents = await Promise.all(uniqueValidIds.map(async agentId => await getAgent(agentId)))
      setAgents(agents)
    }





  
  }
  const currency  =  i18n.language ==='he' ? "ILS"  :"ILS"
  useEffect(() => {
    // const attribtes = JSON.parse(localStorage.getItem('company-attributes'))
    // setCompanyName(attribtes.companyFullName)

    
  });

 
  const reloadTable = async () =>{
    await getItems(lastfilter, '',lastPage)
  }

  useEffect(() => {
    

      async function fetchData() {
        try{
          setHasMext(false)
          setHasBack(false)

        console.log(`sending`)
          const customers = await getCustomers()
          if ( getDataFromStorage('app-token') ) {
            axiosOptions.headers['Authorization']  = 'Bearer ' + getDataFromStorage('app-token'); 
          }
          const agent = await axios.get(`https://rsc3d4it9h.execute-api.eu-west-1.amazonaws.com/dev/agentDetails`,axiosOptions);
          if(customers.data.lastEvaluatedKey){
            setHasMext(true)
            setPages([customers.data.lastEvaluatedKey.accountId])
          }
          console.log(`agent ${JSON.stringify(agent.data)}`)
          return {agent: agent.data, customers: customers.data.items}
        }catch(e){
          localStorage.clear()
          navigate('/login')
        }
        
    };
    async function fetchCustoomerData(items) {
      const allDetails = await Promise.all(items.map(async item => await getCustomerDetails(item.accountId)))
      setCustomerDetails(allDetails)

      const agentIds = allDetails.map(detail => detail.agentId)
      const parentAgentIds = allDetails.map(detail => detail.parentAgentId)
      const topAgentIds = allDetails.map(detail => detail.topAgentId)
      const combinedArray = [...agentIds, ...parentAgentIds, ...topAgentIds];
    // Filter out "N/A" and empty strings, then get unique values
      const uniqueValidIds = [...new Set(combinedArray.filter(id => id && id !== "N/A"))];
      const agents = await Promise.all(uniqueValidIds.map(async agentId => await getAgent(agentId)))
      setAgents(agents)
    }
   
  

    fetchData().then((o) => {
      if(!customers){
        setLoadProject(true)
      }
        console.log(`PROJECT ${JSON.stringify(o)}`)
        setCustomers(o.customers)
        setAgentDetails(o.agent)
        localStorage.setItem('user', JSON.stringify(o.agent))       
        setLoadProject(false)
        fetchCustoomerData(o.customers)
        
        
     
    });

  } , [])



  

  
  return(
    <LoggedInLayout  cssClass={'page-bitspace'}>
      
        <AppSection removePadding={'both'}>
        <AppContainer>
         {!loadProject && <AppLoggedInTitle title={`שלום ${aggentDetails.details.firstName}`} />}
          {!loadProject ?
          <AppCol cols={2} justifycontent={'space-between'} cssClass={'app-row-70-30'}>
          
              <AppCol justifycontent={'center'} cssClass={'app-bitspace-user-credit'}>

              <AppBox justifycontent={'center'} cssClass={'app-coin-box app-coin-box-1'}>
              <AppRow rows={3} justifycontent={'center'} cssClass={'app-bitspace-user-credit'}>

                 <StatsBarChart/>
              </AppRow>
                   
                </AppBox>
                <AppCol>
                <AppTabs reloadTable={reloadTable} agents={agents} cutomersDetails= {cutomersDetails} items= {customers}  hasBack={hasBack} hasNext = {hasNext} getItems={getItems} hasData = {customers ? true: false} />   
              </AppCol>
                
              </AppCol>
          </AppCol>
          :
          <div style={{   marginTop: '15vw' , marginRight: '45vw'   }}>
                  
          <AppLoader/>
          </div>
          }


          
        </AppContainer>
      </AppSection>
      

    
    </LoggedInLayout>
  )
} 


export default Page