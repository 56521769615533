import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';  
import  axiosOptions from "api/project/axios/axios";
import './table.css';
import { PDFDocument, StandardFonts } from 'pdf-lib';
import Papa from 'papaparse';
import { v4 as uuidv4 } from 'uuid';
import fontkit from '@pdf-lib/fontkit';
import { FaCopy , FaBeer} from 'react-icons/fa'; // Icon for copy action


import { getDataFromStorage, cleanAll } from '../../../../../../../localStorage/localStorage'
// Assuming each item has a firstName and lastName property
export const CustomersTable = ({ items, getItems ,cutomersDetails, hasNext, hasBack, reloadTable, agents}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [fileToken, setFileToken] = useState({});
    const [isLoadingDocument, setIsLoadingDocument] = useState(false);
 
    const [editModal, setEditModal] = useState(false);
    const [secureMailModal, setSecureMailModal] = useState(false);
    const [selectedModalItem, setSelecteModaldRow] = useState({});

    const [selected, setSelected] = useState('');
    const user = JSON.parse(localStorage.getItem('user'))

    const handleChange = async (e) => {
      const { value } = e.target;
      setSelected(value);
      setIsLoading(true);
      await getItems(value)
      setIsLoading(false);
    };
  
    
    const openModal = async (customer) => {
        setSelectedCustomer(customer);
        setIsModalOpen(true);
        setIsLoading(true);

        try {
            if ( getDataFromStorage('app-token') ) {
                axiosOptions.headers['Authorization']  = 'Bearer ' + getDataFromStorage('app-token'); 
              }
            const response = await axios.get(`https://rsc3d4it9h.execute-api.eu-west-1.amazonaws.com/dev/customers/${customer.accountId}`,axiosOptions);
        
            setSelectedCustomer({ ...customer, ...response.data });
            setIsLoading(false);    
            setIsLoadingDocument(true)
            const accesTokenResponse = await axios.get(`https://rsc3d4it9h.execute-api.eu-west-1.amazonaws.com/dev/customers/accesstoken/${customer.accountId}`, axiosOptions)
            setFileToken(accesTokenResponse.data.AccessToken)
            setIsLoadingDocument(false)
        } catch (error) {
            setIsLoading(false);
            setIsLoadingDocument(false)
            navigate('/')
            cleanAll()
            console.error('Error fetching customer data:', error);
            // Handle error accordingly
        }
     
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setEditModal(false)
        setSecureMailModal(false)
    };


    const goToNextPage = async  () => {
        console.log('next')

        setIsLoading(true);
        await  getItems(selected,'NEXT')
        setIsLoading(false);
    };

    const goToPrevPage = async (e) => {
        e.preventDefault();
        setIsLoading(true);
         getItems(selected,'BACK')
        setIsLoading(false);
    };
    const [selectedRow, setSelectedRow] = useState(null);

    const handleRadioChange = (item) => {
        setSelectedRow(item);
    };


    const openEditModal = async () => {
        if (selectedRow) {
            setIsLoading(true);    
            setEditModal(true)
            try {
                if ( getDataFromStorage('app-token') ) {
                    axiosOptions.headers['Authorization']  = 'Bearer ' + getDataFromStorage('app-token'); 
                  }
                let details = cutomersDetails.filter(detail =>detail.accountId === selectedRow.accountId )
                if(details.length > 0 ){
                    setSelecteModaldRow({ ...selectedRow, ...details[0] });
                }else{
                    const response = await axios.get(`https://rsc3d4it9h.execute-api.eu-west-1.amazonaws.com/dev/customers/${selectedRow.accountId}`,axiosOptions);
                
                    setSelecteModaldRow({ ...selectedRow, ...response.data });
                }
                setIsLoading(false);    
                setIsLoadingDocument(true)
                const accesTokenResponse = await axios.get(`https://rsc3d4it9h.execute-api.eu-west-1.amazonaws.com/dev/customers/accesstoken/${selectedRow.accountId}`, axiosOptions)
                setFileToken(accesTokenResponse.data.AccessToken)
                setIsLoadingDocument(false)
            } catch (error) {
                setIsLoading(false);
                setIsLoadingDocument(false)
                navigate('/')
                cleanAll()
                console.error('Error fetching customer data:', error);
                // Handle error accordingly
            }
            // Logic to open the modal for editing
        }
    };

    const openAddModal = () => {
        setEditModal(true)
        setSelecteModaldRow({})
        // Logic to open the modal for adding a new row
    };

   

    const openSecureModal = () => {
        setSecureMailModal(true)
        let details = cutomersDetails.filter(detail =>detail.accountId === selectedRow.accountId )
        if(details.length > 0 ){
            setSelecteModaldRow({ ...selectedRow, ...details[0] })
        }
    }
   
    return (
        <div className="table-container">
               <div className="table-tab">
            <div className="table-actions">
                <button onClick={openEditModal}>עריכה</button>
                <button onClick={openAddModal}>הוסף לקוח חדש</button>
                {user.role ==='ADMIN'  &&  <button onClick={openSecureModal}>שלח מייל מאובטח</button>}
             
    </div>  
    <div className="filter-container">
      <label htmlFor="statusFilter" className="filter-label">סנן:</label>
      <select id="statusFilter" value={selected} onChange={handleChange} className="filter-select">
      <option value=""></option>
        <option value="NEW">{t("NEW")}</option>
        <option value="DOCUMENT_IN_PROGRESS">{t("DOCUMENT_IN_PROGRESS")}</option>
        <option value="STARTING">{t("STARTING")}</option>
        <option value="DONE">{t("DONE")}</option>
        <option value="SENT_DETAILS">{t("SENT_DETAILS")}</option>
        <option value="RECIVED_OFFER">{t("RECIVED_OFFER")}</option>
        <option value="LOAN_RECIVED">{t("LOAN_RECIVED")}</option>
      </select>

    </div>

            </div>
            <table>
            <thead>
                    <tr>
                    <th></th>
                        <th>מזהה לקוח</th>
                        <th>שם לקוח</th>
                        <th>מספר טלפון</th>
                        {(user.role ==='ADMIN' || user.role ==='TOP_AGENT' || user.role ==='PARENT_AGENT')&& <th>סוכן</th>}
                        {(user.role ==='ADMIN' || user.role === 'TOP_AGENT') && <th>סוכן אב</th>}
                        {user.role ==='ADMIN' && <th>סוכן סב</th>}
                        <th>תאריך פתיחת לקוח</th>
                        <th>ציון BDI</th>
                        <th>הלוואה מבוקשת</th>
                        <th>עדכון אחרון</th>
                        <th>סטטוס מסמכים</th>
                        <th>סטטוס</th>
                        
                        <th>סכום הלוואה</th>
                        </tr>
                        </thead>
                <tbody>
                    {items  && items.length > 0 ? items.map((item, index) => (

                        
                        <tr key={index}>
                            <td>
                                <input 
                                    type="radio" 
                                    name="selectRow" 
                                    onChange={() => handleRadioChange(item)} 
                                    checked={selectedRow === item} 
                                />
                            </td>
                              <td 
            onClick={() => openModal(item)} 
            style={{ 
                cursor: 'pointer', 
                color: 'blue', 
                textDecoration: 'underline' 
            }}
        >
            {item.accountId.substring(0,4)}
        </td>
        <td> {getName(cutomersDetails,item.accountId)}</td>
        <td> {getPhone(cutomersDetails,item.accountId)}</td >
        
        {(user.role ==='ADMIN' || user.role ==='TOP_AGENT' || user.role ==='PARENT_AGENT') &&<td>{getAgnetName(agents, item.agentId)}</td>}
        {(user.role ==='ADMIN' || user.role === 'TOP_AGENT') &&<td>{getAgnetName(agents,item.parentAgentId)}</td>}
        {user.role ==='ADMIN' &&<td>{getAgnetName(agents,item.topAgentId)}</td>}
        <td> {item.createdDate? new Date(item.createdDate).toLocaleString() : ''}</td>
                            
                          
        <td> {item.bdiScore ? formatNumberString(normaelizeFormat(item.bdiScore)): ''} </td>
               
                         
                         
                            <td>{formatNumberString(normaelizeFormat(item.requestedLoan))}</td>

                            <td>{new Date(item.lastUpdate).toLocaleString()}</td>
                            <td>
                    {getStatus(cutomersDetails,item) ? getStatus(cutomersDetails,item) : 'טוען'}
                    </td>
                            <td style={{ color: item.status === 'DONE' || item.status === 'LOAN_RECIVED' ? 'green' : 'inherit' }}>
                            
                {t(item.status)}
                
            </td>
            
            <td >
                            
                            {formatNumberString(item.loanSum)}
                        </td>
                        </tr>
                    )) : ''}
                </tbody>
            </table>
            <div className="pagination">
              {hasBack &&  <button onClick={goToPrevPage} >קודם</button>}
              {hasNext && <button onClick={goToNextPage} >הבא</button>}
            </div>
            {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={closeModal} isLoading={isLoading}>
                 <AddEditModal cutomersDetails={cutomersDetails}readOnly={true} onClose={closeModal}  t={t} selectedModalItem={selectedCustomer} isLoadingDocument={isLoadingDocument} fileToken={fileToken} setIsLoadingDocument={setIsLoadingDocument} navigate={navigate} >
           </AddEditModal>
        </Modal>
    )}

    {editModal &&
        <Modal isOpen={editModal} onClose={closeModal}  isLoading={isLoading}>

        <AddEditModal cutomersDetails={cutomersDetails} reloadTable={reloadTable} onClose={closeModal}  t={t} selectedModalItem={selectedModalItem} isLoadingDocument={isLoadingDocument} fileToken={fileToken} setIsLoadingDocument={setIsLoadingDocument} navigate={navigate} >
           </AddEditModal>
           </Modal>}

{secureMailModal &&
    <Modal isOpen={secureMailModal} onClose={closeModal}  isLoading={isLoading}>
        <SecuredEmailModal onClose={closeModal} cutomersDetails={cutomersDetails} selectedModalItem={selectedModalItem} />
       </Modal>}
    </div>
    );  
};

const normaelizeFormat = (message) => {
    if (message === 'N/A') {
        return 'חסר מידע';
    }
    return message;
};

function getStatus(customerDetails, customer){
    if(!customerDetails){
        return ''
    }
    const customerDetail = customerDetails.filter(detail => detail.accountId === customer.accountId )
    if(customerDetail.length > 0){

        const documents = customerDetail[0].documents
        return `${documents.length} המסמכים `
    }
    return ''
}
function formatNumberString(str) {
    // First, check if the string is a valid number
    if (!isNaN(str) && !isNaN(parseFloat(str))) {
        // Convert the string to a number then back to a string with commas
        return parseFloat(str).toLocaleString('en-US');
    } else {
        // Return the original string if it's not a valid number
        return str;
    }
}


async function downloadDocument(documentToken, documentName, fileToken, setIsLoadingDocument, documentType) {

    
    const url = `https://8b6oy2kgs9.execute-api.eu-west-1.amazonaws.com/dev/secureStorage/${documentToken}`;

    try {
        const headers = {
            'Authorization': `Bearer ${fileToken}`,
            'download-type':'base64',
            'transaction-id': uuidv4()
        }
        console.log(`documentName ${documentName} documentType ${documentType}`)

        setIsLoadingDocument(true)
        const response = await fetch(url, {
            method: 'GET',
            headers: headers
        });

        setIsLoadingDocument(false)
        if (!response.ok) {
            throw new Error('Network response was not ok.');
        }

       

        
        if(documentType && documentType.indexOf('csv') > -1){
            const textReposne =  await response.text()
            const fontResponse = await fetch('https://back4u.bismarter.co.il/assets/font/nehama.ttf'); // Adjust the font file path
            const fontBytes = await fontResponse.arrayBuffer();
            createAndOpenPdfFromText(textReposne, fontBytes, documentName, response)
          

        
            
        }else{
            const blob = await response.blob();
            const a = document.createElement("a");
            // Create a URL for the blob
            const blobUrl = URL.createObjectURL(blob);
            a.href = blobUrl;

            // Provide a filename (you might want to derive this from the response or the URL)
            a.download = documentName ; // Replace 'filename.ext' with the actual filename and extension

            // Append the anchor to the body, trigger the download, and remove the anchor
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            // Revoke the blob URL after a delay
            setTimeout(() => URL.revokeObjectURL(blobUrl), 100);
                    window.open(blobUrl, '_blank');

                }
        
        // setFileUrl(localUrl);
        // Open the file in a new tab
        // const downloadUrl = window.URL.createObjectURL(blob);
        // const a = document.createElement('a');
        // a.style.display = 'none';
        // a.href = downloadUrl;
        // a.download = documentName; // or use a dynamic name based on the document
        // document.body.appendChild(a);
        // a.click();
        // window.URL.revokeObjectURL(downloadUrl);
        // document.body.removeChild(a);
    } catch (error) {
        setIsLoadingDocument(false)
        console.error('Error during fetch:', error);
    }
}


function formatIsraeliPhoneNumber(number) {
    if(!number){
        return 
    }
    if(number.length < 13){
        return number
    }
    // Remove the country code +972 and replace with 0
    let localNumber = '0' + number.substring(4);

    // Apply the format 05X-XXX-XXXX
    return localNumber.substring(0, 3) + '-' + localNumber.substring(3, 6) + '-' + localNumber.substring(6);
}

const Modal = ({ isOpen, onClose, children, isLoading }) => {
    if (!isOpen) return null;

    const closeButtonStyle = {
        position: 'absolute',
        top: '10px',
        left: '20px',
        border: 'none',
        height: '500px',
        background: 'none',
        fontSize: '40px',
        cursor: 'pointer',
        color: 'black', // Make sure the color contrasts with the modal background
        padding: '5px', // Add some padding to increase the clickable area
        zIndex: 1000, // Make sure the button is above all other elements
    };
    return (
        <div className="modal-overlay">
            <div className="modal-content" style={{ position: 'relative' }}>
                <button style={closeButtonStyle} onClick={onClose}>×</button>
                {isLoading && <div className="loader"></div>}
                {!isLoading && children}
            </div>
        </div>
    );
};

const SecuredEmailModal = ({onClose, selectedModalItem, cutomersDetails}) =>{
    const navigate = useNavigate();
    
    const sortedDocuments = selectedModalItem ? selectedModalItem.documents ? selectedModalItem.documents.sort((a, b) => a.documentType.localeCompare(b.documentType)) : []:[]
    const tokens= sortedDocuments.map(document =>{
        return {name:`${selectedModalItem.firstName}_${selectedModalItem.lastName}_${document.documentType}`,documenType: document.documentName ,token:document.documentToken}
    })
    const [formData, setFormData] = useState({email:''})
    const [code, setCode] = useState()
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [copySuccess, setCopySuccess] = useState('');
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const createShareToken = async (accountId, email,tokens) =>{
        
      }
    const handleSubmit = async (e) =>{
        e.preventDefault();
      
        try{
            if ( getDataFromStorage('app-token') ) {
              axiosOptions.headers['Authorization']  = 'Bearer ' + getDataFromStorage('app-token'); 
            }
            const data = {
                accountId: selectedModalItem.accountId,
                email: formData.email,
                tokens: tokens
            }
            setLoading(true)
            const response = await axios.post(`https://rsc3d4it9h.execute-api.eu-west-1.amazonaws.com/dev/customers/secureToken`,data,axiosOptions);
            console.log(`response from server ${JSON.stringify(response.data)}`)
            const messageForSecure = `על מנת לצפות במסמכי הלקוח יש להיכנס לאתר הבא: https://securedmail.bismarter.co.il עם המייל ${data.email} ולהכניס את הקוד הבא: ${response.data.loginCode}`;
            setCode(response.data.code)
            setMessage(messageForSecure)
            setLoading(false)
            setCode(code)
          }catch(e){
    
          }
    }
    const copyToClipboard = () => {
        navigator.clipboard.writeText(message).then(() => {
          setCopySuccess('הועתק!');
          setTimeout(() => setCopySuccess(''), 2000); // Reset message after 2 seconds
        }, () => {
          setCopySuccess('שגיאה בהעתקה');
        });
    }

    
    const overlayStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.5)', // semi-transparent white
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
    if(message){
        return (
            <div className='scrollable-div-secured'>
            <div style={{ padding: '20px', border: '1px solid #ccc', borderRadius: '5px', position: 'relative' }}>
                <p>{message}</p>
                <button onClick={copyToClipboard} style={{ position: 'absolute', top: '80px', right: '5px', background: 'transparent' }}>
                <p>העתק</p>
                </button>
          {copySuccess && <div style={{ position: 'absolute', top: '50px', right: '20px', color: 'green' }}>{copySuccess}</div>}
          </div>
          </div>
    )
    }
    return (
        
        <div className='scrollable-div-secured'>

           
             {loading && <div style={overlayStyle}><div className="loader"></div></div>}
                <form onSubmit={handleSubmit} className="form-container">
                    <h2 className="form-title"> הכנת מייל מאובטח עם הפרטים של {selectedModalItem.firstName} {selectedModalItem.lastName}</h2> 
                
                    {/* Input fields */}
                    <div className="form-row">
                        <div className="input-group">
                            <label htmlFor="firstName"> מייל  :</label>
                            <input  id="email" type="text" name="email" placeholder=" " value={formData.email} onChange={handleChange} />
                    
                        </div>
                    </div>
                   <button type="submit" className="submit-button">שלח</button>
                </form>
            </div>
        )

}

const reasons = ['LANDING', 'LOAN_REDUCTION', 'CAR', 'OTHER'];
const employmentTypes = ['CONTRACT', 'FREELANCER'];
const maritalStatuses = ['SINGLE', 'MARRIED', 'DIVORCED', 'WIDOWED'];
const LOAN_STATUS =["DOC_SENT_TO_BANK","LAON_SENT_TO_BANK_WITHOUT_DOCS","LOAN_APPROVED_IN_PRINCIPAL_WATINING_FOR_DOCS",
"LAON_APPROVED_IN_PRINCIPLE_BY_BANK",
"LOAN_APPROVED_IN_PROCESS",
"CUSTOMER_DECLINE_OFFER",
"LOAN_GRANTED",
"CUSTOMER_COMITION_NOT_PAYED",
"CUSTOMER_COMITION_PAYED",
"AGENT_COMITION_PAYED"]

const AddEditModal = ({ onClose, selectedModalItem, t , fileToken, setIsLoadingDocument, isLoadingDocument, readOnly, reloadTable, cutomersDetails}) => {
    const [formData, setFormData] = useState({
        firstName: selectedModalItem ? selectedModalItem.firstName : '',
        lastName: selectedModalItem ? selectedModalItem.lastName : '',
        phoneNumber: selectedModalItem ? selectedModalItem.phoneNumber : '',
        salary: selectedModalItem ? selectedModalItem.salary : '',
        reason: selectedModalItem ? selectedModalItem.reason : '',
        carYear: selectedModalItem ? selectedModalItem.carYear : '',
        carValue:  selectedModalItem ? selectedModalItem.carValue : '',
        carBrand:  selectedModalItem ? selectedModalItem.carBrand : '',
        requestedLoan: selectedModalItem ? selectedModalItem.requestedLoan : '',
        monthlyReturn:  selectedModalItem ? selectedModalItem.monthlyReturn : '',
        maritalStatus: selectedModalItem ? selectedModalItem.maritalStatus : '',
        alimony: selectedModalItem ? selectedModalItem.alimony : '',
        pension:  selectedModalItem ? selectedModalItem.pension : '',
        allowance: selectedModalItem ? selectedModalItem.allowance : '',
        rent:selectedModalItem ? selectedModalItem.rent : '',
        loanSum: selectedModalItem? selectedModalItem.loanSum :'',
        status: selectedModalItem ? selectedModalItem.status: '',
        accountId: selectedModalItem? selectedModalItem.accountId: '',
        employmentType: selectedModalItem ? selectedModalItem.employmentType : '',
        partnerEmploymentType: selectedModalItem ? selectedModalItem.partnerEmploymentType : '',
        propertyOwner: selectedModalItem ? selectedModalItem.propertyOwner : '',
    });

    const sortedDocuments = selectedModalItem ? selectedModalItem.documents ? selectedModalItem.documents.sort((a, b) => a.documentType.localeCompare(b.documentType)) : []:[];

  
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const user = JSON.parse(localStorage.getItem('user'))


    const handleSubmit = async (e) => {
        e.preventDefault();
      
        // onSave(formData); // Implement onSave to handle form data

        if ( getDataFromStorage('app-token') ) {
            axiosOptions.headers['Authorization']  = 'Bearer ' + getDataFromStorage('app-token'); 
          }
         setIsLoadingDocument(true)
        const response = await axios.post(`https://rsc3d4it9h.execute-api.eu-west-1.amazonaws.com/dev/customers/profile`,formData,axiosOptions);
        setIsLoadingDocument(false)
        reloadTable()
        onClose(); // Close modal after saving
        if(payslip){
            let salaryFile = await handleFileConvert(payslip)
            const salary = {
                file:salaryFile,
                documentType: 'SALARY',
                accountId: formData.accountId
            }
            await axios.post(`https://rsc3d4it9h.execute-api.eu-west-1.amazonaws.com/dev/customers/profile/document`,salary,axiosOptions);

        }
        if(payslip2){
            let salaryFile = await handleFileConvert(payslip2)
            const salary = {
                file:salaryFile,
                documentType: 'SALARY',
                accountId: formData.accountId
            }
            await axios.post(`https://rsc3d4it9h.execute-api.eu-west-1.amazonaws.com/dev/customers/profile/document`,salary,axiosOptions);

        }
        if(payslip3){
            let salaryFile = await handleFileConvert(payslip3)
            const salary = {
                file:salaryFile,
                documentType: 'SALARY',
                accountId: formData.accountId
            }
            await axios.post(`https://rsc3d4it9h.execute-api.eu-west-1.amazonaws.com/dev/customers/profile/document`,salary,axiosOptions);

        }
        if(bdiReport){
            let salaryFile = await handleFileConvert(bdiReport)
            const salary = {
                file:salaryFile,
                documentType: 'BDI_REPORT',
                accountId: formData.accountId
            }
            await axios.post(`https://rsc3d4it9h.execute-api.eu-west-1.amazonaws.com/dev/customers/profile/document`,salary,axiosOptions);

        }
        if(idPhoto){
            let salaryFile = await handleFileConvert(idPhoto)
            const salary = {
                file:salaryFile,
                documentType: 'ID_DOCUMENT',
                accountId: formData.accountId
            }
            await axios.post(`https://rsc3d4it9h.execute-api.eu-west-1.amazonaws.com/dev/customers/profile/document`,salary,axiosOptions);

        }
        if(idPhoto2){
            let salaryFile = await handleFileConvert(idPhoto2)
            const salary = {
                file:salaryFile,
                documentType: 'ID_DOCUMENT',
                accountId: formData.accountId
            }
            await axios.post(`https://rsc3d4it9h.execute-api.eu-west-1.amazonaws.com/dev/customers/profile/document`,salary,axiosOptions);

        }
        if(idPhoto3){
            let salaryFile = await handleFileConvert(idPhoto3)
            const salary = {
                file:salaryFile,
                documentType: 'ID_DOCUMENT',
                accountId: formData.accountId
            }
            await axios.post(`https://rsc3d4it9h.execute-api.eu-west-1.amazonaws.com/dev/customers/profile/document`,salary,axiosOptions);

        }
        if(idPhoto3){
            let salaryFile = await handleFileConvert(accountStatement)
            const salary = {
                file:salaryFile,
                documentType: 'TRANSACTIONS',
                accountId: formData.accountId
            }
            await axios.post(`https://rsc3d4it9h.execute-api.eu-west-1.amazonaws.com/dev/customers/profile/document`,salary,axiosOptions);

        }

        
    };
    const [payslip, setPayslip] = useState(null);
    const [payslip2, setPayslip2] = useState(null);
    const [payslip3, setPayslip3] = useState(null);
    const [accountStatement, setAccountStatement] = useState(null);
    const [bdiReport, setBdiReport] = useState(null);
    const [idPhoto, setIdPhoto] = useState(null);
    const [idPhoto2, setIdPhoto2] = useState(null);
    const [idPhoto3, setIdPhoto3] = useState(null);
    const [freeLanserReport, setFreeLanserReport] = useState(null);
    const [freeLanserTaxReport, setFreeLanserTaxReport] = useState(null);
    
    // ... other file states as needed

    const handleFreelancerChange = (event) => setFreeLanserReport(event.target.files[0]);
    const handleFreelancerTaxChange = (event) => setFreeLanserTaxReport(event.target.files[0]);

    const handlePayslipChange = (event) => setPayslip(event.target.files[0]);
    const handlePayslipChange2 = (event) => setPayslip2(event.target.files[0]);
    const handlePayslipChange3 = (event) => setPayslip3(event.target.files[0]);
    const handleAccountStatementChange = (event) => setAccountStatement(event.target.files[0]);
    const handleBdiReportChange = (event) => setBdiReport(event.target.files[0]);
    const handleIdPhotoChange = (event) => setIdPhoto(event.target.files[0]);
    const handleIdPhotoChange2 = (event) => setIdPhoto2(event.target.files[0]);
    const handleIdPhotoChange3= (event) => setIdPhoto3(event.target.files[0]);
    const bdiDocument = getDocumentByType(sortedDocuments , "BDI_REPORT")
    const salrayDocs = getDocumentByType(sortedDocuments , "SALARY")
    const idDocs = getDocumentByType(sortedDocuments , "ID_DOCUMENT","ID_DOCUMENT_EXTANSION")
    const transactionsDocs = getDocumentByType(sortedDocuments , "TRANSACTIONS")
    const freeLancerTax = getDocumentByType(sortedDocuments , "FREELANCER_TAX")
    const freeLancerRevenue = getDocumentByType(sortedDocuments , "REVENUE_DOC")
    const overlayStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.5)', // semi-transparent white
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };
    return (
        <div className='scrollable-div'>
        {isLoadingDocument && <div style={overlayStyle}><div className="loader"></div></div>}
        <form onSubmit={handleSubmit} className="form-container">
        {readOnly? <h2 className="form-title">פרטי לקוח</h2>  : selectedModalItem.firstName ? <h2 className="form-title">עריכת לקוח</h2> : <h2 className="form-title">הוספת לקוח</h2>}
    
        {/* Input fields */}
        <div className="form-row">
        <div className="input-group">
            <label htmlFor="firstName">שם פרטי :</label>
            <input  id="firstName" type="text" name="firstName" placeholder=" " value={formData.firstName} onChange={handleChange} readOnly={readOnly} />
        </div>
            <div className="input-group">
            <label htmlFor="lastName">שם משפחה:</label>
            <input type="text" name="lastName" placeholder=" " value={formData.lastName} onChange={handleChange} readOnly={readOnly} />
            </div>
            <div className="input-group">
            <label htmlFor="phoneNumber">טלפון:</label> 
            <input type="text" name="phoneNumber" placeholder="" value={formatIsraeliPhoneNumber(formData.phoneNumber)} onChange={handleChange} readOnly={readOnly} />
            </div>
        </div>
        <div className="form-row">
            <div className="input-group">
            <label htmlFor="lastName">משכורת :</label>
            <input type="number" name="salary" placeholder="" value={formData.salary} onChange={handleChange} readOnly={readOnly}/>
            </div>
            <div className="input-group">
            <label htmlFor="lastName">הלוואה מבוקשת :</label>
            <input type="number" name="requestedLoan" placeholder=" " value={formData.requestedLoan} onChange={handleChange}readOnly={readOnly} />
            </div>
            <div className="input-group">
            <label htmlFor="lastName">החזר הלוואה :</label>
            <input type="number" name="monthlyReturn" placeholder=" " value={formData.monthlyReturn} onChange={handleChange} readOnly={readOnly}/>
            </div>
        </div>
        <div className="form-row">
        <div className="input-group">
            <label htmlFor="lastName">סיבה להלוואה :</label>
            <select name="reason" value={formData.reason} onChange={handleChange} readOnly={readOnly}>
                {readOnly ? <option value="">{t(formData.reason)} </option> : <option value=""> </option>}
                {!readOnly &&reasons.map(reason => <option key={reason} value={reason}>{t(reason)}</option>)}
            </select>
            </div>
            <div className="input-group">
            <label htmlFor="lastName">תעסוקה :</label>
            <select name="employmentType" value={formData.employmentType} onChange={handleChange }readOnly={readOnly}>
            {readOnly ? <option value="">{t(formData.employmentType)} </option> : <option value=""></option>}
                {!readOnly && employmentTypes.map(type => <option key={type} value={type}>{t(type)}</option>)}
            </select>
            </div>
            <div className="input-group">
            <label htmlFor="lastName">מצב משפחתי :</label>
            <select name="maritalStatus" value={formData.maritalStatus} onChange={handleChange} readOnly={readOnly}>
            {readOnly ? <option value="">{t(formData.maritalStatus)} </option> : <option value=""> </option>}
                {!readOnly && maritalStatuses.map(status => <option key={status} value={status}>{t(status)}</option>)}
            </select>
            </div>
        </div>
       
        {formData.reason === 'CAR' &&
        <div className="form-row">
            <div className="input-group">
            <label htmlFor="lastName">שנתון שנתון :</label>
        
            <input type="number" name="carYear" placeholder=" " value={formData.carYear} onChange={handleChange}  readOnly={readOnly} />
            </div>
            <div className="input-group">
            <label htmlFor="lastName">שווי מכונית :</label>
        
            <input type="number" name="carValue" placeholder=" " value={formData.carValue} onChange={handleChange}  readOnly={readOnly} />
            </div>
            <div className="input-group">
            <label htmlFor="lastName">סוג מכונית :</label>
        
            <input type="text" name="carBrand" placeholder=" " value={formData.carBrand} onChange={handleChange}  readOnly={readOnly} />
            </div>
        </div>}
       
        <div className="form-row">
        <div className="input-group">
            <label htmlFor="lastName">פנסיה :</label>
         
            <input type="number" name="pension" placeholder="" value={formData.pension} onChange={handleChange}  readOnly={readOnly} />
            </div>
            <div className="input-group">
            <label htmlFor="lastName">קצבה :</label>
         
            <input type="number" name="allowance" placeholder="" value={formData.allowance} onChange={handleChange}   readOnly={readOnly}/>
            </div>
            <div className="input-group">
            <label htmlFor="lastName">הכנסה משכירות :</label>
        
            <input type="number" name="rent" placeholder=" " value={formData.rent} onChange={handleChange} readOnly={readOnly} />
            </div>
        </div>
        <div className="form-row">
            <div className="input-group">
                <label htmlFor="propertyOwner">נגס בבעלותי :</label>
                <input type="text" name="propertyOwner" placeholder=" " value={formData.propertyOwner === 'true' ? 'כן': 'לא'} onChange={handleChange} readOnly={readOnly}/>
            
            </div>
            <div className="input-group">
            
                <label htmlFor="rentPayment">שכר דירה :</label>
                <input type="number" name="rentPayment" placeholder=" " value={formData.rentPayment} onChange={handleChange} readOnly={readOnly}/>
            
            </div>
           
            
        </div>
        {formData.maritalStatus === 'DIVORCED' &&
        <div className="form-row">
              <div className="input-group">
            <label htmlFor="lastName">מזונות :</label>
         
            <input type="number" name="alimony" placeholder="" value={formData.alimony} onChange={handleChange}   readOnly={readOnly}/>
            </div>
        </div>
        }
        {user.role === 'ADMIN' &&
         <div className="form-row">
        <div className="input-group">
            <label htmlFor="lastName">סטטוס הלוואה  :</label>
            <select name="status" value={formData.status} onChange={handleChange} readOnly={readOnly}>
            {readOnly ? <option value="">{t(formData.status)} </option> : <option value=""> </option>}
                {!readOnly && LOAN_STATUS.map(status => <option key={status} value={status}>{t(status)}</option>)}
            </select>
            </div>
            <div className="input-group">
            <label htmlFor="loanSum">סכום ההלוואה :</label>
         
            <input type="number" name="loanSum" placeholder="" value={formData.loanSum} onChange={handleChange}   readOnly={readOnly}/>
            </div>
        </div>
}
      
        {/* ... more input fields ... */}
    
        <div className="file-upload-section">
        <div className="file-upload">
                    <label htmlFor="bdireport"> דוח BDI</label>
                  
                    {bdiDocument.length > 0 ? <a href="#" onClick={() => downloadDocument(bdiDocument[0].documentToken, `${selectedModalItem.firstName}_${selectedModalItem.lastName}_${bdiDocument[0].documentType}`, fileToken, setIsLoadingDocument)} style={{ color: 'blue' }}>
                                            צפה במסמך
                                        </a>:    !readOnly  && <input type="file" id="bdiReport" onChange={handleBdiReportChange} />}

                </div>
                {formData.employmentType === 'CONTRACT' &&
                <div className="file-upload">
                    <label htmlFor="payslip">משכורות</label>
                    {salrayDocs.length > 0 ? <a href="#" onClick={() => downloadDocument(salrayDocs[0].documentToken, `${selectedModalItem.firstName}_${selectedModalItem.lastName}_${salrayDocs[0].documentType}`, fileToken, setIsLoadingDocument)} style={{ color: 'blue' }}>
                                            צפה במסמך
                                        </a>:  !readOnly  && <input type="file" id="payslip" onChange={handlePayslipChange} />}
                    {salrayDocs.length > 1 ? <div><a href="#" onClick={() => downloadDocument(salrayDocs[1].documentToken, `${selectedModalItem.firstName}_${selectedModalItem.lastName}_${salrayDocs[1].documentType}`, fileToken, setIsLoadingDocument)} style={{ color: 'blue' }}>
                        צפה במסמך
                    </a></div>:   !readOnly  &&  <input type="file" id="payslip2" onChange={handlePayslipChange2} />}
                    {salrayDocs.length > 2 ? <div><a href="#" onClick={() => downloadDocument(salrayDocs[2].documentToken, `${selectedModalItem.firstName}_${selectedModalItem.lastName}_${salrayDocs[2].documentType}`, fileToken, setIsLoadingDocument)} style={{ color: 'blue' }}>
                        צפה במסמך
                    </a></div>:    !readOnly  && <input type="file" id="payslip3" onChange={handlePayslipChange3} />}
                </div>}
                {formData.employmentType === 'FREELANCER' &&
                  <div className="file-upload">
                   <label htmlFor="payslip">דוח רווח הפסד</label>
                   {freeLancerRevenue.length > 0 ? <a href="#" onClick={() => downloadDocument(freeLancerRevenue[0].documentToken, `${selectedModalItem.firstName}_${selectedModalItem.lastName}_${freeLancerRevenue[0].documentType}`, fileToken, setIsLoadingDocument)} style={{ color: 'blue' }}>
                                           צפה במסמך
                                       </a>:    !readOnly  && <input type="file" id="payslip" onChange={handleFreelancerChange} />}
                 {freeLancerRevenue.length > 1 ? <a href="#" onClick={() => downloadDocument(freeLancerRevenue[1].documentToken, `${selectedModalItem.firstName}_${selectedModalItem.lastName}_${freeLancerRevenue[1].documentType}`, fileToken, setIsLoadingDocument)} style={{ color: 'blue' }}>
                                           צפה במסמך
                                       </a>:    !readOnly  && <input type="file" id="payslip" onChange={handleFreelancerChange} />}
                {freeLancerRevenue.length > 2 ? <a href="#" onClick={() => downloadDocument(freeLancerRevenue[2].documentToken, `${selectedModalItem.firstName}_${selectedModalItem.lastName}_${freeLancerRevenue[2].documentType}`, fileToken, setIsLoadingDocument)} style={{ color: 'blue' }}>
                    צפה במסמך
                </a>:    !readOnly  && <input type="file" id="payslip" onChange={handleFreelancerChange} />}
              
                    </div>}

            
                <div className="file-upload">
                    <label htmlFor="accountStatement">דפי חשבון</label>
                    {transactionsDocs.length > 0 ? <div><a href="#" onClick={() => downloadDocument(transactionsDocs[0].documentToken, `${selectedModalItem.firstName}_${selectedModalItem.lastName}_${transactionsDocs[0].documentType}`, fileToken, setIsLoadingDocument, transactionsDocs[0].documentName)} style={{ color: 'blue' }}>
                        צפה במסמך
                    </a></div>:    !readOnly  && <input type="file" id="accountStatement" onChange={handleAccountStatementChange} />}
                </div>
            
                <div className="file-upload">
                    <label htmlFor="accountStatement"> צילום ת.ז</label>
                    {idDocs.length > 0 ? <div><a href="#" onClick={() => downloadDocument(idDocs[0].documentToken, `${selectedModalItem.firstName}_${selectedModalItem.lastName}_${idDocs[0].documentType}`, fileToken, setIsLoadingDocument)} style={{ color: 'blue' }}>
                        צפה במסמך
                    </a></div>:   !readOnly  &&  <input type="file" id="id" onChange={handleIdPhotoChange} />}
                    {idDocs.length > 1 ? <div><a href="#" onClick={() => downloadDocument(idDocs[1].documentToken, `${selectedModalItem.firstName}_${selectedModalItem.lastName}_${idDocs[1].documentType}`, fileToken, setIsLoadingDocument)} style={{ color: 'blue' }}>
                        צפה במסמך
                    </a></div>:    !readOnly  && <input type="file" id="id2" onChange={handleIdPhotoChange2} />}
                   
                </div>
               
                </div>

       { !readOnly && <button type="submit" className="submit-button">שמור</button>}
    </form>
    </div>
    );
};


function getDocumentByType(documents , type, type2){
    const docs = documents? documents.filter(doc => doc.documentType.indexOf(type) > -1) : []
    return docs
}

const fileToBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(',')[1]); // Remove the Data URL part (before the comma)
    reader.onerror = error => reject(error);
});


// Usage example with async/await
const handleFileConvert = async (file) => {
    try {
        const base64String = await fileToBase64(file);
        // Prepare the data to send
        const fileData = {
            fileName: file.name,
            fileType: file.type,
            fileBase64String: base64String
        };
        

        // Now you can send fileData to the server
        console.log(fileData); // Or send it using fetch, axios, etc.
        return fileData
    } catch (error) {
        console.log('Error converting file:', error);
    }
};

const getAgnetName = (agents, agnetId) =>{
    if(!agnetId){
        return 'לא מוגדר'
    }
    if(!agents){
        return "טוען"
    }
    const agent = agents.filter(item => item.agentId ===agnetId )
    if(agent.length > 0){
        return `${agent[0].details.firstName} ${agent[0].details.lastName}`
    }

    return "טוען"
}

function getName(customerDetails, accountId){
    if(customerDetails){
        let customer = customerDetails.filter(detail =>detail.accountId === accountId )
        if(customer.length > 0){
            return `${customer[0].firstName} ${customer[0].lastName}`
        }
    }
    return 'טוען'
}

function getPhone(customerDetails, accountId){
    if(customerDetails){
        let customer = customerDetails.filter(detail =>detail.accountId === accountId )
        if(customer.length > 0){
            return `${formatIsraeliPhoneNumber(customer[0].phoneNumber)} `
        }
    }
    return 'טוען'
}

function reverseHebrewText(text) {
    const hebrewCharPattern = /[\u0590-\u05FF\uFB1D-\uFB4F]/;
    return text.split(/(\d+|\s+)/).map((segment) => {
      // Reverse the segment if it contains a Hebrew character, otherwise return as is
      return hebrewCharPattern.test(segment) ? segment.split('').reverse().join('') : segment;
    }).join('');
  }
  
const createAndOpenPdfFromText = async (text, fontBytes, documentName, response) => {
    const doc = await PDFDocument.create();
    doc.registerFontkit(fontkit); // Register fontkit with the PDF document
  
    // Embed the font
    const customFont = await doc.embedFont(fontBytes);
  
    Papa.parse(text, {
      header: true,
      skipEmptyLines: true,
      complete: async function(results) {
        const page = doc.addPage();
        const fontSize = 10; // Set the font size to 10 or any other desired size
  
        // Add headers
        if (results.data.length > 0) {
          let headers = Object.keys(results.data[0]);
          headers.forEach((header, idx) => {
            page.drawText(reverseHebrewText(header), {
              x: 50 + (idx * 100),
              y: page.getHeight() - 30,
              size: fontSize,
              font: customFont,
            });
          });
        }
  
        // Add data below headers
        results.data.forEach((row, index) => {
          let yPos = page.getHeight() - 50 - (index * (fontSize + 10)); // Adjust Y position based on font size
          Object.values(row).forEach((value, idx) => {
            page.drawText(reverseHebrewText(value), {
              x: 50 + (idx * 100),
              y: yPos,
              size: fontSize,
              font: customFont,
            });
          });
        });
  
        const pdfBytes = await doc.save();
        const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, '_blank');

    
      }
    });
  };