import  axiosOptions from "api/project/axios/axios";
import { getDataFromStorage } from '../../../localStorage/localStorage'
import axios from 'axios';

export const getCustomers = async (filter,operation) => {
    if ( getDataFromStorage('app-token') ) {
        axiosOptions.headers['Authorization']  = 'Bearer ' + getDataFromStorage('app-token'); 
      }
    return new Promise( async (resolve , reject) => {

        let o = {
            response:false,
            data:{},
            err:[]
        };
        let url = filter ? `/?status=${filter}` : "/"
        if(operation && operation.accountId && operation.lastUpdate){
            url = filter ? operation ? `/?status=${filter}&lastEvaluatedKey=${operation.accountId}&lastUpdate=${operation.lastUpdate}&status=${operation.status}` : url : operation ? `/?lastEvaluatedKey=${operation.accountId}&lastUpdate=${operation.lastUpdate}&status=${operation.status}` : "/"

        }else{
            url = filter ? operation ? `/?status=${filter}&lastEvaluatedKey=${operation.accountId}` : url : operation ? `/?lastEvaluatedKey=${operation.accountId}` : "/"

        }
       console.log(`url ${url}`)
        if ( o.err.length  === 0 ) {
            o.response = true; 
            try {
                const res = await axios.get(url, axiosOptions);
                o.data = res.data;
                resolve(o);          
            } catch (e ){
                console.log("r" , e);
                o.err.push("Server error ");   
                reject(o);
            }
        } else{
            resolve(o);
        }

    });
    
}