import { AppDiv } from "components/AppDiv/AppDiv";
import { Link } from "react-router-dom";
import  "./AppLoader.css";


import {ReactComponent as AppLoaderIcon} from  "svgs/loader/loader.svg";

export const AppLoader = ({color =  "black"}) => {


  const cssClasses = [];
  cssClasses.push('app-loader-component-wrapper');
  cssClasses.push(color);
  
    return(
      <AppDiv cssClass={cssClasses.join(" ")}>
          <AppLoaderIcon />
      </AppDiv>
    )
  }
  