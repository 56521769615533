import { AppDiv } from 'components/AppDiv/AppDiv';
import "./AppBox.css";

/**
  * 
  * +
*/
export const AppBox = ( {cssClass , children , removeBorder} ) => {
    
    const cssClasses = [];

    cssClasses.push('app-admin-panel-box');  
    
    
    if ( removeBorder === true ) {
        cssClasses.push('app-remove-border');  
    }
    cssClasses.push(cssClass);


  
    return(
        <AppDiv cssClass={cssClasses.join(" ")}>
            {children}
        </AppDiv>
    )
}