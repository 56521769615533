import {AppDiv} from '../AppDiv/AppDiv';

export const AppTitle = ({cssClass , component , children ,  textAlign='' , removeMargin=''}) => { 
    let html = 
    <h1>
      {children}
    </h1>
    
    if ( component == "h2" ) {
      html = <h2> {children} </h2>
    }
    
    if ( component == "h3" ) {
      html = <h3> {children} </h3>
    }

    if ( component == "h4" ) {
      html = <h4> {children} </h4>
    }

    if ( component == "h5" ) {
      html = <h5> {children} </h5>
    }

    if ( component == "h6" ) {
      html = <h6> {children} </h6>
    }

    let cssClasses = [
      'app-title-component'
    ];


    if ( textAlign != '' ) {
      cssClasses.push('app-text-align-'+ textAlign);
    }

    if ( removeMargin ) {
        cssClasses.push('app-remove-margin-' + removeMargin);
    }
    
    cssClasses.push('app-title-component ' + cssClass);
    
    
    return (
      <AppDiv cssClass={cssClasses.join(" ")}>
         {html}
      </AppDiv>
    )
  }